<template>
  <div class="env-label__div">
    <span
      class="user-fluid-layout__env user-fluid-layout__env--md"
      id="user-fluid-layout__env"
      >{{ $t(`environment.${allConfig.environment}.title`) }}</span
    >
    <span
      class="user-fluid-layout__env user-fluid-layout__env--sm"
      id="user-fluid-layout__env--small"
      >{{ $t(`environment.${allConfig.environment}.mobile_title`) }}</span
    >

    <b-tooltip
      target="user-fluid-layout__env"
      custom-class="user-fluid-layout__env--tooltip"
    >
      {{ $t(`environment.${allConfig.environment}.disclaimer.content`) }}
    </b-tooltip>

    <b-tooltip
      placement="bottom"
      target="user-fluid-layout__env--small"
      custom-class="user-fluid-layout__env--tooltip small"
    >
      <p>{{ $t(`environment.${allConfig.environment}.disclaimer.content`) }}</p>
      <LxpButton variant="outline-grey" block @click="onClose">{{
        $t("general.close")
      }}</LxpButton>
    </b-tooltip>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpButton } from "didactica";
export default {
  components: { LxpButton },
  computed: {
    ...mapGetters(["allConfig"])
  },
  methods: {
    onClose() {
      this.$root.$emit("bv::hide::tooltip", "user-fluid-layout__env--small");
    }
  }
};
</script>
<style lang="scss">
.user-fluid-layout__env {
  background: $brand-primary-100;
  padding: 4px 8px;
  border-radius: 8px;
  color: $brand-primary-400;
  margin: 0 0 0 16px;
  text-transform: capitalize;
  cursor: pointer;
  @include body-medium;
  &.user-fluid-layout__env--md {
    display: flex;
  }
  &.user-fluid-layout__env--sm {
    display: none;
  }
}

// Toolitp styles
.user-fluid-layout__env--tooltip {
  &.small {
    .tooltip-inner {
      max-width: 300px;
      .lxp-button {
        color: #fff;
        border-color: #fff;
      }
    }
  }
  .tooltip-inner {
    max-width: 360px;
    padding: 16px;
    text-align: left;
  }
}

[dir="rtl"] {
  .user-fluid-layout__env {
    margin: 0 16px 0 0;
  }
}
@media screen and (max-width: 1199px) {
  .env-label__div {
    .user-fluid-layout__env {
      &.user-fluid-layout__env--md {
        display: none;
      }
      &.user-fluid-layout__env--sm {
        display: flex;
      }
    }
  }
}
@media screen and (max-width: 355px) {
  .user-fluid-layout__env--tooltip {
    &.small {
      .tooltip-inner {
        max-width: 250px;
      }
    }
  }
}
</style>
